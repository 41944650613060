import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Aufstehen`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` German political party “Aufstehen” brings most participants ever into a Polis conversation to form the party's platform.  `}</p>
    <p><strong parentName="p">{`When:`}</strong>{` 2018`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Where:`}</strong>{` Germany`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Size:`}</strong>{` 33,547 participants  `}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` Aufstehen, meaning “stand-up”; is an emergent German political party founded in Summer 2018 in response to the growth of right-wing political parties. A Polis conversation was run in the Fall of 2018 to gain understanding on how coalition building could be used to build broad consensus, and inform the creation of the party’s political platform in accordance with the will of its members. This demonstrated how technology can bring about new and innovative ideas to the democratic process. At time of writing, this conversation retains the world record for largest number of participants in a single Polis conversation.  `}</p>
    <p><strong parentName="p">{`Overview:`}</strong>{` Dreiwerk Entertainment, an independent production company, was supporting the media appearances of Aufstehen, and introduced pol.is to party members. `}</p>
    <h3>{`Links`}</h3>
    <ul>
      <li parentName="ul">{`Report: `}<a parentName="li" {...{
          "href": "https://pol.is/report/r6xd526vyjyjrj9navxrj"
        }}>{`https://pol.is/report/r6xd526vyjyjrj9navxrj`}</a></li>
      <li parentName="ul">{`Aufstehen on wikipedia: `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Aufstehen"
        }}>{`https://en.wikipedia.org/wiki/Aufstehen`}</a></li>
      <li parentName="ul">{`The client/media partner: `}<a parentName="li" {...{
          "href": "https://www.dreiwerk.tv/"
        }}>{`https://www.dreiwerk.tv/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      